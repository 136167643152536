import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import executiveChart from 'stile-shared/assets/images/women-in-stem/executive_chart.svg';
import leadershipChart from 'stile-shared/assets/images/women-in-stem/leadership_chart.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function WomenInStemPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/women-in-stem/cover_women_in_stem.png"
            alt="A young woman presents her ideas in front of a whiteboard displaying technical writing"
          />
        }
      />

      <TextBlock>
        <P>
          Picture a scientist. Chances are, you saw a white man in a lab coat. You aren’t alone.
        </P>

        <P>
          <Link url="https://www.chiefscientist.gov.au/sites/default/files/2020-07/australias_stem_workforce_-_final.pdf">
            Women represent 50% of the university-qualified science population in Australia, but
            only 29% of the workforce.
          </Link>{' '}
          The statistics are even worse in engineering with fewer women undertaking engineering at a
          tertiary level and making up only 14% of the workforce.
        </P>

        <P>
          Systemic barriers stop many women from entering, progressing, and being recognised for
          their achievements in science, technology, engineering, and mathematics. These barriers
          begin during childhood and impact women throughout their careers.
        </P>

        <P>At Stile, we’re uniquely positioned to help.</P>

        <P>
          Over a hundred thousand students use Stile each day. This means we can have an outsized
          impact by accurately representing genders in our classroom resources and communicating to
          the next generation that both men and women are crucial in building the future. As a
          company, particularly one made up of many software engineers and scientists, we can lead
          by example and continue to build a truly inclusive, passionate team.
        </P>
      </TextBlock>

      <TeaserBlock
        variant="beige"
        contentMin="700px"
        title="Sharing the careers of female scientists"
        text="Scientists act as guides throughout our resources, leading students through units and sharing their stories in career profiles. We’re proactive in who we choose to feature. A recent audit found that 56% of scientists depicted in our lessons are female."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/women-in-stem/career-profiles.png"
            alt="A compilation of some of the illustrated portraits of scientists included in our lessons"
          />
        }
      />

      <MediaBlock layout="center-align-three">
        <StaticImage
          src="../../../../stile-shared/assets/images/women-in-stem/comp_women-in-stem-01.png"
          alt="Our poster featuring Amy Wong, a Canadian biologist"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/women-in-stem/comp_women-in-stem-02.png"
          alt="Our poster featuring Stephanie Wilson, an American engineer and NASA astronaut"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/women-in-stem/comp_women-in-stem-03.png"
          alt="Our poster encouraging students, but especially female students, to follow their passions"
        />
      </MediaBlock>

      <TextBlock title="Celebrating women in STEM">
        <P>
          Each year, we create resources that celebrate the astounding contributions that women have
          made to science and engineering. We hope these encourage more girls to see STEM as a real
          possibility for their future and demonstrate to boys that girls are equally capable of
          contributing to STEM.
        </P>

        <P>
          Recently, we’ve created lessons and posters on Dr Emmanuelle Charpentier and Dr Jennifer
          Doudna, Dr Stephanie Wilson and Dr Amy Wong. All proceeds from the sale of these posters
          went to the Domestic Violence Resource Centre Victoria. We’ve also written a{' '}
          <Link url="https://blog.stileeducation.com/celebrating-women-in-stem/">
            blog on gender bias and strategies to tackle gender bias in the classroom
          </Link>
          , and{' '}
          <Link url="https://blog.stileeducation.com/flash_pl_recordings/">
            hosted webinars and round-table discussions
          </Link>
          .
        </P>
      </TextBlock>

      <TextBlock title="Continuing to build an inclusive team">
        <Column space={theme.space.l}>
          <P>
            To build the best possible resources and meaningfully move the state of education
            forward, we need a team that is truly inclusive and represents our community. Gender
            equality is the first area we’re hoping to make a real impact.
          </P>

          <Row justify="space-evenly">
            <img src={leadershipChart} alt="Pie chart showing the leadership team is 50% female" />
            <img src={executiveChart} alt="Pie chart showing the executive team is 50% female" />
          </Row>

          <P>
            While we’re delighted that 50% of our leadership and executive teams are female, we know
            that we still have work to do.
          </P>
        </Column>
        <P>
          Software engineers built Stile, and we’re actively looking for more to realise our full
          potential. We’ve recently set targets to hire more experienced female engineers and now
          provide paid internships for young women, non-binary and transgender people.
        </P>
      </TextBlock>

      <TextBlock variant="dark" title="Gender Diversity in Tech: Software engineering internship">
        <P>
          There’s a staggering shortage of engineers in Australia, particularly software engineers.
          But, when we speak to gender-diverse students interested in STEM about careers in
          engineering, they tell us that it’s something for boys.
        </P>

        <P>
          Our paid internship provides comprehensive, ongoing training for one to two successful
          Year 12 applicants each year.
        </P>

        <P>
          We hope this internship breaks down some of the barriers that stop young women,
          non-binary, and transgender people from entering the engineering industry.
        </P>
      </TextBlock>

      <TeaserBlock
        style={{ paddingTop: 0 }}
        variant="dark"
        contentMax="400px"
        title="Meet Dev and Ikram"
        text="Our first-ever software engineering interns! Watch the video to hear about their experiences with the internship."
        video={{
          url: 'https://youtu.be/yk2CsNiLqXs',
          text: 'Meet Dev and Ikram',
          coverImage: (
            <StaticImage
              src="../../../../stile-shared/assets/images/women-in-stem/internship_video_cover.jpg"
              alt="A closeup showing Dev and Ikram in conversation"
            />
          ),
        }}
      />

      <TeaserBlock
        title="Celebrating diversity"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/women-in-stem/rainbow_stile.png"
            alt="The Stile logo with a background of diagonal rainbow stripes"
          />
        }
      >
        <P>
          While we’re focusing on supporting gender diversity in STEM, it isn’t the only thing we’re
          doing. We’re deeply committed to creating science lessons that authentically represent our
          society, elevate underrepresented groups and challenge our preconceived biases. Our team
          considers everything from race to age, body types, power dynamics and family models when
          crafting our resources. We strive to foster a diverse, inclusive and welcoming community
          that celebrates our differences — we know that’s how we build the best team and science
          resources. Read{' '}
          <Link url="https://blog.stileeducation.com/celebrating-pride-month/">
            this blog celebrating Pride Month at Stile
          </Link>
          .
        </P>
      </TeaserBlock>

      <NextPageBlock path="/who-we-are/join-the-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default WomenInStemPage;
